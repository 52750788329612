import React from 'react';
import ThemeProvider from "./providers/ThemeProvider";
import {BrowserRouter, HashRouter} from "react-router-dom";
import RootRouter from "./RootRouter";
import MediaProvider from "./providers/MediaProvider";
import SocketProvider from "./providers/SocketProvider";
import {Row} from "./style/layout/Row";

function App() {
  return (
      <SocketProvider>
          <MediaProvider>
              <ThemeProvider>
                  <Row css={{
                        width: '100%',
                        height: 100,
                        backgroundColor: 'black',
                        color: 'white',
                        alignItems: 'center',
                        justifyContent: 'center',
                  }}>
                        <h1>WebRTC Test</h1>
                  </Row>
                  <HashRouter basename="/">
                      <RootRouter/>
                  </HashRouter>
              </ThemeProvider>
          </MediaProvider>
      </SocketProvider>
  );
}

export default App;
