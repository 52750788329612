import { create } from 'zustand'

interface SocketStore {
    client:any,
    remoteId:string,
    setRemoteId:(remoteId:string)=>void,
    isConnect:boolean,
    connect:(clientId:string)=>void,
    disconnect:()=>void,
    sendMessage:(message:any)=>void,
    create:(roomName:string, offer:any)=>void,
    join:(roomName:string)=>void,
    sendCandidate:(iceCandidate:any)=>void,
}

export const useSocketStore = create<SocketStore>((set, get) => ({
    client:null,
    isConnect:false,
    remoteId:'',
    setRemoteId:(remoteId:string)=>{
        set((state)=>({
            remoteId:remoteId
        }))
    },
    connect:(client:any)=>{
        set((state)=>({
            client:client,
            isConnect:true
        }))
    },
    disconnect:()=>{
        set((state)=>({
            isConnect:false,
            client:null
        }))
    },
    sendCandidate:(iceCandidate:any)=>{
        let {remoteId,client} = get();
        client.emit('iceCandidate',{
            target:remoteId,
            candidate:iceCandidate
        });
    },
    sendMessage:(message:any)=>{
        get().client.emit('message',message)
    },
    create:(roomName:string, offer)=>{
        get().client.emit('create',{
            room:roomName,
            offer:offer
        })
    },
    join:(roomName:string)=>{
        get().client.emit('join',{
            room:roomName
        })
    }
}));
