import React from 'react';
import {Route, Routes, Outlet} from 'react-router-dom';
import HomePage from './containers/Home/HomePage';
import {Col} from './style/layout/Col';

function RootRouter() {
    return (
        <Routes>
            <Route path={'/'}>
                <Route path={'/'} element={<Body/>}>
                    <Route path={'/'} element={<HomePage/>}/>
                </Route>
            </Route>
        </Routes>
    );
}

function Body() {
    return (
        <Col
            css={{
                width: '100%',
                flex: 1,
            }}
        >
            <Col
                css={{
                    width: '100%',
                    paddingTop: '36px',
                }}
            >
                <Outlet/>
            </Col>
        </Col>
    );
}

export default RootRouter;
