import { styled } from '../stitches.config';
import { Div } from './Div';

export const Row = styled(Div, {
  flexDirection: 'row',
  flexWrap: 'wrap',

  variants: {
    full: {
      true: {
        width: '100%',
      },
    },
    ss: {
      true: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
    },
    sc: {
      true: {
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    ec: {
      true: {
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    },
    bc: {
      true: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
    cc: {
      true: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
});
