import React, {useEffect, useRef, useState} from 'react';
import {useMediaInfoStore} from "../../store/mediaInfoStore";
import {Row} from "../../style/layout/Row";
import axios from "axios";
import {Col} from "../../style/layout/Col";
import {useSocketStore} from "../../store/socketStore";

function HomePage() {
    const localVideoRef = useRef<any>(null);
    const remoteVideoRef = useRef<any>(null);
    const {localStream, remoteStream, peerConnection} = useMediaInfoStore();
    const {create, join} = useSocketStore();

    useEffect(() => {
        if (!localStream || !localVideoRef) {
            return;
        }
        localVideoRef.current.srcObject = localStream;
    }, [localVideoRef, localStream]);

    useEffect(() => {
        if (!remoteStream || !remoteVideoRef) {
            return;
        }
        remoteVideoRef.current.srcObject = remoteStream;
    }, [remoteVideoRef, remoteStream]);

    const createOffer = async ()=>{
        if (peerConnection === undefined) {
            return;
        }
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        create(roomName, offer);
    }

    const joinChat = async () =>{
        join(roomName);
    }

    const [roomName, setRoomName] = useState<string>('');

    return (
        <Col css={{
            width: '100%',
        }}>
            <Row css={{
                width: '100%'
            }}>
                <video ref={localVideoRef} autoPlay style={{
                    flex:1,
                    height: 500,
                    backgroundColor: 'black',
                    border: '1px solid black',
                }}></video>
                <video ref={remoteVideoRef} autoPlay style={{
                    flex:1,
                    height: 500,
                    backgroundColor: 'black',
                    border: '1px solid black',
                }}></video>
            </Row>
            <Col css={{
                width: '100%',
                marginTop: 20,
            }}>
                <input style={{
                    width: '100%',
                    height: 50,
                }} value={roomName} onChange={(e)=>setRoomName(e.target.value)} placeholder={'방이름 입력'}/>
                <button onClick={createOffer} style={{
                    marginTop: 20,
                    width: '100%',
                    height: 50,
                }}>방생성</button>
                <button onClick={joinChat} style={{
                    marginTop: 20,
                    width: '100%',
                    height: 50,
                }}>들어가기</button>
            </Col>
        </Col>
    );
}

export default HomePage;
