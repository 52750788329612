import { create } from 'zustand'

interface MediaInfoStore {
    audio:MediaDeviceInfo[],
    video:MediaDeviceInfo[],
    addMediaInfo:(media:MediaDeviceInfo)=>void,
    localStream?:MediaStream,
    setLocalStream:(stream:MediaStream)=>void,
    remoteStream?:MediaStream,
    setRemoteStream:(stream:MediaStream)=>void,
    peerConnection?:RTCPeerConnection,
    setPeerConnection:(peerConnection:RTCPeerConnection)=>void,
}


export const useMediaInfoStore = create<MediaInfoStore>((set, get) => ({
    audio:[],
    video:[],
    addMediaInfo:(media:MediaDeviceInfo)=>{
        //중복 체크
        if(media.kind.startsWith('audio')){
            if(get().audio.some((item)=>item.deviceId === media.deviceId)){
                return;
            }
        }
        if(media.kind.startsWith('video')){
            if(get().video.some((item)=>item.deviceId === media.deviceId)){
                return;
            }
        }

        if(media.kind === 'audiooutput'){
            set((state)=>({
                audio:[...state.audio,media]
            }))
        }else if(media.kind === 'videoinput'){
            set((state)=>({
                video:[...state.video,media]
            }))
        }else if (media.kind === 'audioinput'){
            set((state)=>({
                audio:[...state.audio,media]
            }))
        }else if (media.kind === 'videooutput'){
            set((state)=>({
                video:[...state.video,media]
            }))
        }else {
            console.log('media.kind',media.kind)
        }
    },
    localStream:undefined,
    setLocalStream:(stream:MediaStream)=>{
        set((state)=>({
            localStream:stream
        }))
    },
    remoteStream:undefined,
    setRemoteStream:(stream:MediaStream)=>{
        set((state)=>({
            remoteStream:stream
        }))
    },
    peerConnection:undefined,
    setPeerConnection:(peerConnection:RTCPeerConnection)=>{
        set((state)=>({
            peerConnection:peerConnection
        }))
    }
}))
