import React, {useEffect, useState} from 'react';
import {useMediaInfoStore} from "../store/mediaInfoStore";
import {useSocketStore} from "../store/socketStore";


type Props = {
    children: React.ReactNode | React.ReactNode[];
}
function MediaProvider({children}: Props) {
    const {addMediaInfo,localStream , setLocalStream,remoteStream, setRemoteStream, peerConnection, setPeerConnection} = useMediaInfoStore();
    const {sendCandidate} = useSocketStore();

    useEffect(() => {
        getConnectedDevices().then(()=>{
            console.log('init MediaProvider');
        })
        navigator.mediaDevices.addEventListener('devicechange', getConnectedDevices);

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getConnectedDevices);
        };
    }, []);

    useEffect(() => {
        initPeerConnection().then(()=>{
            console.log('init PeerConnection');
        });
    }, []);

    async function initPeerConnection() {
        const newPeerConnection = new RTCPeerConnection({
            iceServers: [
                { urls: 'stun:super-turn.win:19402', username:"user", credential:"rudgh4465" }
            ]
        });

        // ICE Candidate 설정
        newPeerConnection.onicecandidate = event => {
            if (event.candidate) {
                console.log('sending ice candidate', event.candidate);
                sendCandidate(event.candidate);
            }
        };

        // 원격 스트림 설정
        newPeerConnection.ontrack = (event:RTCTrackEvent) => {
            console.log('remote stream added');
            console.dir(event.streams);
            setRemoteStream(event.streams[0]);
        };

        newPeerConnection.addEventListener('connectionstatechange', event => {
            console.log('connection state change', newPeerConnection.connectionState);
            if (newPeerConnection.connectionState === 'connected') {
                // Peers connected!
                console.log('Peers connected!');
            }
        });

        try{
            const constraints = { audio: true, video: true };
            //권한 정의 및 획득
            let localStream  = await navigator.mediaDevices.getUserMedia(constraints);
            if(!localStream) return;
            setLocalStream(localStream);
            // 로컬 스트림 트랙을 RTCPeerConnection에 추가
            localStream.getTracks().forEach((track:any) => {
                newPeerConnection.addTrack(track, localStream);
            });
        }catch (e) {
            console.log(e);
        }

        setPeerConnection(newPeerConnection);
    }


    async function getConnectedDevices() {
        let list:MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
        for(let i=0; i<list.length; i++){
            addMediaInfo(list[i]);
        }
    }

    return (
        <>{children}</>
    );
}

export default MediaProvider;
