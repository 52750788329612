import React, {useEffect, useRef} from 'react';
import {io} from "socket.io-client";
import {useMediaInfoStore} from "../store/mediaInfoStore";
import {useSocketStore} from "../store/socketStore";

type Props = {
    children: React.ReactNode | React.ReactNode[];
}

const SERVER_URL = 'wss://api.super-turn.win';

function SocketProvider({children}: Props) {

    const socketRef = useRef<any>(null);

    const {connect, disconnect, setRemoteId} = useSocketStore();
    const {peerConnection} = useMediaInfoStore();

    useEffect(() => {
        if(peerConnection === undefined){
            console.log('peerConnection is undefined');
            return;
        }
        console.log('init SocketProvider');
        socketRef.current = io(SERVER_URL);

        socketRef.current.on('connect', () => {
            console.log('socket connected');
            connect(socketRef.current);
        });
        socketRef.current.on('disconnect', () => {
            console.log('socket disconnected');
            disconnect();
        });
        socketRef.current.on('remote', async(message:any) =>{
            console.log(message.remote)
            setRemoteId(message.remote);
        });
        socketRef.current.on('iceCandidate', async (message:any) => {
            if (message.candidate) {
                console.log('received ice candidate', message.candidate);
                await peerConnection.addIceCandidate(message.candidate);
            }
        });
        socketRef.current.on('offer', async (message:any) => {
            console.dir(message.offer);
            console.log('received offer');
            await peerConnection.setRemoteDescription(new RTCSessionDescription(message.offer));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            socketRef.current.emit('answer', {
                target: message.sender,
                answer: answer,
            });
        });
        socketRef.current.on('answer', async (message:any) => {
            console.log('received answer');
            console.log(message.answer);
            await peerConnection.setRemoteDescription(new RTCSessionDescription(message.answer));
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        }
    }, [peerConnection]);

 return (
  <>{children}</>
 );
}

export default SocketProvider;
