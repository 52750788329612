import React from 'react';
import {darkTheme, lightTheme} from '../style/stitches.config';
import {useAppStore} from "../store/appStore";

function ThemeProvider({children}) {
    const {theme} = useAppStore();

    return (
        <div className={theme === 'dark' ? darkTheme : lightTheme}>
            {children}
        </div>
    );
}

export default ThemeProvider;
