import { createStitches } from '@stitches/react';
import darkThemeConfig from './config/dark.json';
import lightThemeConfig from './config/light.json';

export const mediaConfig = {
  fold: '(max-width: 280px)',
  mb: '(max-width: 640px)',
  tb: '(min-width: 641px) and (max-width: 1479px)',
  dt: '(min-width: 1480px)',
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: mediaConfig,
});

export const darkTheme = createTheme('dark-theme', darkThemeConfig);
export const lightTheme = createTheme('light-theme', lightThemeConfig);
